import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { useAuth } from './hooks/useAuth';

import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from './context/auth-context';
import MainLayout from './components/layout/Main/MainLayout';
import { AnimatePresence } from 'framer-motion';

const Auth = React.lazy(() => import('./pages/auth'));
const QueuePage = React.lazy(() => import('./pages/queue'));
const StatementsPage = React.lazy(() => import('./pages/statements'));
const ClientsPage = React.lazy(() => import('./pages/clients'));
const ClientPage = React.lazy(() => import('./pages/client'));
const ClientStatementPage = React.lazy(() => import('./pages/clientStatement'));
const ProfilePage = React.lazy(() => import('./pages/profile'));
const SettingsPage = React.lazy(() => import('./pages/settings'));
const DashboardMain = React.lazy(() => import('./pages/dashboard'));
const ResetPassword = React.lazy(() => import('./pages/resetPassword'));
const OrdersPage = React.lazy(() => import('./pages/orders'));
const InvoicingPage = React.lazy(() => import('./pages/invoicing'));
const CreateInvoicePage = React.lazy(() => import('./pages/createInvoice'));
const ViewInvoicePage = React.lazy(() => import('./pages/viewInvoice'));
const ClientInvoicingPage = React.lazy(() => import('./pages/clientInvoicing'));
const JournalPage = React.lazy(() => import('./pages/journal'));
const InventoryPage = React.lazy(() => import('./pages/inventory'));
const TaxRegisterPage = React.lazy(() => import('./pages/taxRegister'));

function App() {
  const { userState, checkingAuth, login, logout, changeContextItem } =
    useAuth();
  const location = useLocation();

  if (checkingAuth) {
    return <CircularProgress />;
  }

  if (!userState.isLoggedIn) {
    return (
      <AuthContext.Provider
        value={{
          currencies: userState.currencies,
          languages: userState.languages,
          login,
          logout,
        }}
      >
        <Suspense>
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Auth />} />
          </Routes>
        </Suspense>
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: userState.isLoggedIn,
        token: userState.token,
        user: userState.userId,
        email: userState.email,
        VATpayer: userState.VATpayer,
        VATrate: userState.VATrate,
        userAlias: userState.userAlias,
        units: userState.units,
        currencies: userState.currencies,
        avatar: userState.avatar,
        languages: userState.languages,
        language: userState.language,
        theme: userState.theme,
        themes: userState.themes,
        services: userState.services,
        currency: userState.currency,
        invoiceLogo: userState.invoiceLogo,
        taxNumber: userState.taxNumber,
        login,
        logout,
        changeContextItem,
      }}
    >
      <MainLayout>
        <Suspense>
          <AnimatePresence mode="wait">
            <Routes key={location.pathname} location={location}>
              <Route path="/main" element={<DashboardMain />} />
              <Route path="/queue" element={<QueuePage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/clients" element={<ClientsPage />} />
              <Route path="/clients/:clientId" element={<ClientPage />} />
              <Route
                path="/statements/:clientId"
                element={<ClientStatementPage />}
              />
              <Route path="/statements" element={<StatementsPage />} />
              <Route path="/invoicing" element={<InvoicingPage />} />
              <Route
                path="/invoicing/new-invoice"
                element={<CreateInvoicePage />}
              />
              <Route
                path="invoicing/:clientId"
                element={<ClientInvoicingPage />}
              />
              <Route
                path="invoicing/reverse/:clientId/:invoiceId"
                element={<CreateInvoicePage />}
              />
              <Route
                path="invoicing/view/:clientId/:invoiceId"
                element={<ViewInvoicePage />}
              />
              <Route path="/journal" element={<JournalPage />} />
              <Route path="/inventory" element={<InventoryPage />} />
              <Route path="/tax-register" element={<TaxRegisterPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="*" element={<DashboardMain />} />
            </Routes>
          </AnimatePresence>
        </Suspense>
      </MainLayout>
    </AuthContext.Provider>
  );
}

export default App;
