import { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { useHttpClient } from './useHttpClient';
import { useTranslation } from 'react-i18next';

export const useUploadImage = (person = 'user', personId, personAvatar) => {
  const { avatar, invoiceLogo, token, changeContextItem, language } =
    useContext(AuthContext);

  const [loadingFile, setLoadingFile] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState(
    person === 'client' ? '' : person === 'invoiceLogo' ? invoiceLogo : avatar
  );
  const { t } = useTranslation();

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    if (person === 'client') {
      const getClientAvatar = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/clients/client/${personId}`,
            'GET',
            null,
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
              'Accept-Language': language,
            }
          );
          setPreviewAvatar(responseData.message.avatar);
        } catch (error) {}
      };
      getClientAvatar();
    }
  }, []);

  const changeAvatarHandler = async (url) => {
    if (person === 'client') {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/clients/modify-client`,
          'PATCH',
          JSON.stringify({ clientId: personId, avatar: url }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Accept-Language': language,
          }
        );
      } catch (error) {}
    } else if (person === 'user') {
      changeContextItem('avatar', url);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/update`,
          'POST',
          JSON.stringify({ avatar: url }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Accept-Language': language,
          }
        );
      } catch (error) {}
    } else {
      changeContextItem('invoiceLogo', url);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/update`,
          'POST',
          JSON.stringify({ invoiceLogo: url }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Accept-Language': language,
          }
        );
      } catch (error) {}
    }
  };

  const filePicker = useRef();

  const getFilePicker = () => filePicker.current.click();

  function uploadFile(file, signedRequest, url) {
    setLoadingFile(true);

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          setPreviewAvatar(url);
          document.getElementById('avatar-url').value = url;
          if (person === 'client') {
            personAvatar = url;
          }

          setLoadingFile(false);
        } else {
          alert(t('fileUpload.uploadFailed'));
        }
      }
    };
    xhr.send(file);
  }

  function getSignedRequest(file) {
    const xhr = new XMLHttpRequest();
    if (person === 'client') {
      xhr.open(
        'GET',
        `${process.env.REACT_APP_BACKEND_URL}/clients/${personId}/sign-s3?file-name=${file.name}&file-type=${file.type}`
      );
    } else if (person === 'invoiceLogo') {
      xhr.open(
        'GET',
        `${process.env.REACT_APP_BACKEND_URL}/user/sign-s3?file-name=${file.name}&file-type=${file.type}&invoiceLogo=true`
      );
    } else {
      xhr.open(
        'GET',
        `${process.env.REACT_APP_BACKEND_URL}/user/sign-s3?file-name=${file.name}&file-type=${file.type}`
      );
    }
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          uploadFile(file, response.signedRequest, response.url);
          changeAvatarHandler(response.url);
        } else {
          alert(t('fileUpload.failedToSign'));
        }
      }
    };

    xhr.send();
  }

  const filePickerHandler = (e) => {
    const MIME_TYPE_MAP = {
      'image/png': 'png',
      'image/jpeg': 'jpeg',
      'image/jpg': 'jpg',
      'image/svg+xml': 'svg',
    };

    const files = e.target.files;
    const file = files[0];

    if (file.size > 60000 || !MIME_TYPE_MAP[file.type]) {
      return alert(t('fileUpload.sizeExceeded'));
    }
    if (file == null) {
      return alert(t('fileUpload.noFile'));
    }
    getSignedRequest(file);
  };

  return {
    loadingFile,
    previewAvatar,
    filePicker,
    getFilePicker,
    filePickerHandler,
  };
};
