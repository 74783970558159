import i18next from 'i18next';

export const translateUnits = (source) => {
  const units = source.map((unit) => {
    if (unit === '2000cw/s')
      return {
        value: unit,
        number: 2000,
        mu: i18next.t('appUnits.mu.2000cw/s'),
        short: i18next.t('appUnits.short.2000cw/s'),
        long: i18next.t('appUnits.2000cw/s'),
        forms: i18next.t('appUnits.forms.2000cw/s'),
      };
    else if (unit === 'word')
      return {
        value: unit,
        number: 1,
        mu: i18next.t('appUnits.mu.word'),
        short: i18next.t('appUnits.short.word'),
        long: i18next.t('appUnits.word'),
        forms: i18next.t('appUnits.forms.word'),
      };
    else if (unit === '300w')
      return {
        value: unit,
        number: 300,
        mu: i18next.t('appUnits.mu.300w'),
        short: i18next.t('appUnits.short.300w'),
        long: i18next.t('appUnits.300w'),
        forms: i18next.t('appUnits.forms.300w'),
      };
    else if (unit === '1800cw/os')
      return {
        value: unit,
        number: 1800,
        mu: i18next.t('appUnits.mu.1800cw/os'),
        short: i18next.t('appUnits.short.1800cw/os'),
        long: i18next.t('appUnits.1800cw/os'),
        forms: i18next.t('appUnits.forms.1800cw/os'),
      };
    else if (unit === '-')
      return {
        value: unit,
        mu: '-',
        short: '-',
        long: '-',
        forms: '-',
      };
  });

  if (units.length === 1) return units[0];

  return units;
};

export const translateServices = (source) => {
  const services = source.map((service) => {
    if (service === 'translation')
      return {
        value: service,
        displayedValue: i18next.t('appServices.translation'),
      };
    if (service === 'proofreading')
      return {
        value: service,
        displayedValue: i18next.t('appServices.proofreading'),
      };
    if (service === 'postediting')
      return {
        value: service,
        displayedValue: i18next.t('appServices.postEditing'),
      };
  });
  if (services.length === 1) return services[0];

  return services;
};

export const translateStatus = (source) => {
  const status = source.map((status) => {
    if (status === 'queue')
      return {
        value: status,
        displayedValue: i18next.t('orders.statuses.queue'),
      };
    if (status === 'completed')
      return {
        value: status,
        displayedValue: i18next.t('orders.statuses.completed'),
      };
    if (status === 'invoiced')
      return {
        value: status,
        displayedValue: i18next.t('orders.statuses.invoiced'),
      };
  });

  if (status.length === 1) return status[0];

  return status;
};
