import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/useHttpClient';

const Notifications = () => {
  const { language, token, user } = useContext(AuthContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [notifications, setNotifications] = useState([]);

  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const openNotifications = Boolean(notificationsAnchor);
  const openNotificationsHandler = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };
  const closeNotificationsHandler = () => {
    setNotificationsAnchor(null);
  };

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const getNotifications = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/notifications`,
        'GET',
        null,
        { Authorization: 'Bearer ' + token, 'Accept-Language': language }
      );
      setNotifications(responseData.notifications);
    };

    getNotifications();
  }, []);

  const handleMarkAll = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/notifications/mark-read`,
      'POST',
      null,
      { Authorization: 'Bearer ' + token, 'Accept-Language': language }
    );
    setNotifications(responseData.notifications);
  };

  const unreadNotifications = notifications.filter(
    (notification) => !notification.read.includes(user)
  );

  return (
    <React.Fragment>
      <IconButton onClick={openNotificationsHandler}>
        <Badge
          badgeContent={unreadNotifications.length}
          color="primary"
          max={999}
        >
          <NotificationsIcon color="action" sx={{ fontSize: '3rem' }} />
        </Badge>
      </IconButton>
      <Popover
        open={openNotifications}
        anchorEl={notificationsAnchor}
        onClose={closeNotificationsHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Grid container direction="column" sx={{ maxWidth: '40rem' }}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Grid item>
              <Typography variant="cardTitle">
                {t('headerNotifications.notifications')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={unreadNotifications.length === 0}
                onClick={handleMarkAll}
              >
                <DoneAllIcon
                  sx={{
                    fill:
                      unreadNotifications.length === 0
                        ? ''
                        : theme.palette.secondary.main,
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          {notifications.map((notification, index) => (
            <React.Fragment key={index}>
              <Grid item sx={{ p: 2 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {notification.icon === 'newUser' ? (
                      <CelebrationIcon />
                    ) : (
                      <AnnouncementIcon />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="notificationTitle"
                      sx={{
                        color: notification.read.includes(user)
                          ? 'faded.main'
                          : '',
                      }}
                    >
                      {notification.title[`${language}`]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {!notification.read.includes(user) && <NewReleasesIcon />}
                  </Grid>
                </Grid>

                <Typography
                  variant="notificationMessage"
                  sx={{
                    color: notification.read.includes(user) ? 'faded.main' : '',
                  }}
                >
                  {notification.message[`${language}`]}
                </Typography>
                <Typography variant="notificationDate">
                  {new Date(notification.createdAt).toLocaleDateString(
                    language
                  )}
                </Typography>
              </Grid>
              <Divider />
            </React.Fragment>
          ))}
        </Grid>
      </Popover>
    </React.Fragment>
  );
};

export default Notifications;
