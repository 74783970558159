import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogComponent from '../../UIElements/Modals/DialogComponent';
import RequestResult from '../../UIElements/Feedback/RequestResult';
import { useHttpClient } from '../../../../hooks/useHttpClient';
import { AuthContext } from '../../../../context/auth-context';

const SettingsModal = (props) => {
  const { t, i18n } = useTranslation();
  const {
    token,
    language,
    languages,
    currency,
    currencies,
    changeContextItem,
  } = useContext(AuthContext);

  const [inputs, setInputs] = useState({ language, currency });
  const [success, setSuccess] = useState(null);

  const clearSuccess = () => setSuccess(null);

  const { sendRequest, isLoading, error, clearError } = useHttpClient();

  useEffect(() => {
    const getUserData = async () => {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user`,
        'GET',
        null,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          'Accept-Language': language,
        }
      );
    };

    getUserData();
  }, [sendRequest, token]);

  const changeInputHandler = (value, field) => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/update`,
        'POST',
        JSON.stringify(inputs),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          'Accept-Language': inputs.language,
        }
      );
      i18n.changeLanguage(inputs.language);
      changeContextItem('language', inputs.language);
      changeContextItem('currency', inputs.currency);
      setSuccess(responseData.message);
      props.onClose();
    } catch (error) {}
  };

  const content = (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <InputLabel htmlFor="language">
          {t('modals.user.settings.language')}
        </InputLabel>
        <TextField
          select
          id="language"
          value={inputs.language}
          onChange={(e) => changeInputHandler(e.target.value, 'language')}
        >
          {languages.map((language, index) => (
            <MenuItem key={index} value={language}>
              {language}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <InputLabel htmlFor="language">
          {t('modals.user.settings.currency')}
        </InputLabel>
        <TextField
          select
          value={inputs.currency}
          onChange={(e) => changeInputHandler(e.target.value, 'currency')}
        >
          {currencies.map((currency, index) => (
            <MenuItem key={index} value={currency}>
              {currency}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );

  if (currencies.length === 0 || languages.length === 0) return;

  return (
    <React.Fragment>
      <RequestResult
        severity={error ? 'error' : 'success'}
        content={error || success}
        onClose={() => {
          clearError();
          clearSuccess();
        }}
      />

      <DialogComponent
        open={props.open}
        onClose={props.onClose}
        maxWidth="sm"
        fullWidth
        component="form"
        onSubmit={submitHandler}
        title={t('modals.user.settings.header')}
        content={content}
        actions={
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            {t('buttons.saveBtn')}
          </LoadingButton>
        }
      />
    </React.Fragment>
  );
};

export default SettingsModal;
