import React from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import AppBar from './AppBar';
import Drawer from '../Navigation/Drawer';

const MainLayout = (props) => {
  const matchesSm = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const [open, setOpen] = React.useState(matchesSm ? true : false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />

      <Drawer
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />

      <Box
        sx={{
          padding: '2rem 2rem 0 2rem',
          background: theme.palette.blueFaded.blueFaded100,
          width: '100%',
          minHeight: 'calc(100vh - 7rem)',
          borderTopRightRadius: '1rem',
          borderTopLeftRadius: '1rem',
          marginTop: '7rem',
          marginRight: matchesSm ? '1rem' : 0,
          flexGrow: 1,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default MainLayout;
