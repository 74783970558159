import React from 'react';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import MenuTwoToneIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Header from '../Header/Header';

const drawerWidth = 260;

const Bar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: '#fff',
  [theme.breakpoints.down('sm')]: {
    background: '#fff',
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = ({ open, handleDrawerOpen }) => {
  return (
    <Bar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="secondary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            borderRadius: '5px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuTwoToneIcon />
        </IconButton>
        <Header />
      </Toolbar>
    </Bar>
  );
};

export default AppBar;
