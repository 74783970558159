import React, { useState, useEffect, useContext } from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Breadcrumbs as BCrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import TabIcon from '@mui/icons-material/Tab';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { AuthContext } from '../../../context/auth-context';

const Breadcrumbs = () => {
  const { token, language } = useContext(AuthContext);

  const { t } = useTranslation();
  const location = useLocation();

  const [clientName, setClientName] = useState('');

  const pathnames = location.pathname.split('/').filter((x) => x);

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    if (!pathnames[1] || pathnames[1].length !== 24) return;
    const getClientName = async () => {
      const client = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/clients/client/${pathnames[1]}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + token, 'Accept-Language': language }
      );

      setClientName(client.message.name);
    };
    getClientName();
  }, []);

  return (
    <div role="presentation">
      <BCrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          to="/main"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('breadcrumbs.dashboard')}
        </Link>
        {pathnames.map((path, index, array) => {
          if (pathnames[0] === 'main' || index === 3) return;
          return (
            <Link
              key={index}
              component={index === array.length - 1 ? '' : RouterLink}
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to={`/${path}`}
            >
              <TabIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {path.length === 24 ? clientName : t(`breadcrumbs.${path}`)}
            </Link>
          );
        })}
      </BCrumbs>
    </div>
  );
};

export default Breadcrumbs;
