import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import { useHttpClient } from '../../../hooks/useHttpClient';
import { AuthContext } from '../../../context/auth-context';

const Search = () => {
  const { token, language } = useContext(AuthContext);
  const { sendRequest, isLoading } = useHttpClient();
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickAway = () => {
    setOpen(false);
    setSearchResults([]);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setOpen(true);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Escape') {
      setQuery('');
      setSearchResults([]);
      setOpen(false);
      return;
    }

    if (event.key === 'Enter' || event.type === 'click') {
      setOpen(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/clients`,
          'GET',
          null,
          { Authorization: 'Bearer ' + token, 'Accept-Language': language }
        );

        const matches = [];

        setSearchResults(() => {
          responseData.message.clients.forEach((client) => {
            for (const order of client.orders) {
              if (
                order.reference.toLowerCase() === query.toLowerCase() &&
                matches.filter((match) => match._id === order._id).length === 0
              ) {
                matches.push({
                  _id: order._id,
                  type: t('search.order'),
                  label: `${client.name} REF - ${order.reference} - ${new Date(
                    order.receivedDate
                  ).toLocaleDateString(language)}`,
                  route: `/orders?id=${order._id}`,
                });
              }
            }

            for (const invoice of client.invoices) {
              if (
                invoice.prefix
                  .toLowerCase()
                  .concat(invoice.number)
                  .includes(query.toLowerCase()) &&
                matches.filter((match) => match._id === invoice._id).length ===
                  0
              ) {
                matches.push({
                  _id: invoice._id,
                  type: t('search.invoice'),
                  label: `${client.name} # - ${invoice.prefix}/${
                    invoice.number
                  } - ${new Date(invoice.issuedDate).toLocaleDateString(
                    language
                  )}`,
                  route: `/invoicing?id=${invoice._id}`,
                });
              }
            }

            if (
              client.name.toLowerCase().includes(query.toLowerCase()) &&
              matches.filter((match) => match._id === client._id).length === 0
            ) {
              matches.push({
                _id: client._id,
                type: t('search.client'),
                label: `${client.name}`,
                route: `/clients/${client._id}`,
              });
            }
          });

          return matches;
        });
      } catch (error) {}
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? (
                  <IconButton>
                    <CircularProgress
                      sx={{
                        width: '20px !important',
                        height: '20px !important',
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleKeyDown}>
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          type="text"
          placeholder={t('search.placeholder')}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          sx={{ width: '60rem', position: 'relative' }}
        />

        {open ? (
          <Paper
            sx={{
              position: 'absolute',
              borderRadius: 0,
              padding: '2rem 1rem',
              width: '60rem',
              maxHeight: '90vh',
              overflowY: 'scroll',
            }}
          >
            <Grid container direction="column" spacing={2}>
              {searchResults.map((result, index) => (
                <Grid item key={index} sx={{ padding: '1rem' }}>
                  <Chip label={result.type} />
                  <Link
                    component={RouterLink}
                    to={result.route}
                    onClick={() => {
                      handleClickAway();
                      setQuery('');
                    }}
                    sx={{ display: 'block', padding: '1rem 0' }}
                  >
                    {result.label}
                  </Link>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </Paper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default Search;
