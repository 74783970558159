import { createTheme } from '@mui/material/styles';

const userData = JSON.parse(localStorage.getItem('userData'));
const setTheme = userData?.user.theme;

const zentBluePrimary = '#1864ab';
const zentBlueSecondary = '#5b9fba';
const accentColor = '#ccc633';

const textDarkPrimary = '#3D1139';
const textDarkSecondary = '#606A73';
const textLightPrimary = '#ecf0f1';
const textFaded = '#969FA7';
const links = '#8173CA';
const background = '#fff';

const blueFaded100 = '#f4f9ff';
const blueFaded80 = '#e9f3ff';
const blueFaded60 = '#dfedff';
const blueFaded40 = '#d4e7ff';
const blueFaded20 = '#bedbff';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 10,

    h1: {
      fontSize: '3.6rem',
    },

    h2: {
      fontSize: '3.3rem',
    },

    h3: {
      fontSize: '3rem',
    },

    h4: {
      fontSize: '2.7rem',
    },

    h5: {
      fontSize: '2.4rem',
    },

    h6: {
      fontSize: '2rem',
    },

    notificationTitle: {
      display: 'block',
      fontSize: '1.4rem',
      fontWeight: 700,
    },
    notificationMessage: {
      display: 'block',
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    notificationDate: {
      display: 'block',
      fontSize: '1.2rem',
      color: zentBlueSecondary,
      textAlign: 'right',
    },
  },
  palette: {
    // mode: setTheme || 'light',

    common: {
      black: textDarkPrimary,
      white: textLightPrimary,
    },

    primary: {
      main: zentBluePrimary,
    },
    secondary: {
      main: zentBlueSecondary,
    },
    text: {
      primary: textDarkPrimary,
      secondary: textDarkSecondary,
      white: textLightPrimary,
    },
    background: {
      default: background,
    },
    faded: {
      main: textFaded,
    },
    blueFaded: {
      blueFaded100,
      blueFaded80,
      blueFaded60,
      blueFaded40,
      blueFaded20,
    },
    accentColor,
  },

  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '2rem',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 1rem rgba(0, 0, 50, 0.05)',
          borderRadius: '1rem',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            padding: '1rem 1rem 1rem 0',
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: links,
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: '600',
          marginBottom: '5px',
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          borderRight: 'none',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#9e9e9e',
          fontWeight: 400,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: { fill: zentBlueSecondary },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          '&.Mui-disabled': {
            '.MuiSvgIcon-root': { fill: '#ebebeb' },
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          border: '1px solid transparent',
          transition: 'all 1s',

          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${zentBlueSecondary}`,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${zentBlueSecondary}`,
          },
        },
        input: {
          padding: '1rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontSize: '1.6rem',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '2.4rem',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        icon: {
          '& .MuiSvgIcon-root': {
            fill: '#fff',
          },
        },
        action: {
          '& .MuiSvgIcon-root': {
            fill: '#fff',
          },
        },
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: '56px !important',
        },
      },
    },
  },
});
