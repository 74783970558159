import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';

import SettingsModal from '../../COMMON/Modals/UserModals/SettingsModal';
import Notifications from './Notifications';
import Breadcrumbs from './Breadcrumbs';

import ProfileMenu from './ProfileMenu';
import Search from './Search';

const Header = () => {
  const matchSm = useMediaQuery('(min-width:1100px)');
  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  return (
    <React.Fragment>
      <SettingsModal
        open={openSettings}
        onClose={() => setOpenSettings(false)}
      />
      <Grid
        container
        justifyContent={matchSm ? 'space-between' : 'flex-end'}
        alignItems="center"
        spacing={1}
      >
        {matchSm && (
          <Grid item>
            <Breadcrumbs />
          </Grid>
        )}
        {matchSm && (
          <Grid item>
            <Search />
          </Grid>
        )}
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton component={RouterLink} to="/help">
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item>
              <Notifications fontSize="medium" />
            </Grid>
            <Grid item>
              <ProfileMenu handleOpenSettings={handleOpenSettings} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Header;
