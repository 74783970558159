import { useState, useEffect, useCallback, useReducer } from 'react';
import { useJwt } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import {
  translateUnits,
  translateServices,
} from '../utilities/translate-units';
import { useHttpClient } from './useHttpClient';

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_APP_DATA':
      const units = translateUnits(action.appData.units);

      const services = translateServices(action.appData.services);

      return {
        ...state,
        currencies: action.appData.currencies,
        units,
        languages: action.appData.languages,
        themes: action.appData.themes,
        services,
      };

    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: !!action.token,
        token: action.token,
        tokenExpirationDate: action.tokenExpirationDate,
        userId: action.user._id,
        email: action.user.email,
        VATpayer: action.user.VATpayer,
        VATrate: action.user.VATrate || 0,
        userAlias: action.user.alias,
        currency: action.user.currency,
        avatar: action.user.avatar,
        theme: action.user.theme,
        language: action.user.language,
        invoiceLogo: action.user.invoiceLogo,
        taxNumber: action.user.taxNumber,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        tokenExpirationDate: null,
        userId: null,
        email: null,
        VATpayer: false,
        VATrate: 0,
        userAlias: null,
        units: [],
        avatar: '',
        theme: '',
        themes: [],
        language: '',
        services: [],
        currency: '',
        invoiceLogo: '',
        taxNumber: '',
      };

    case 'CHANGE_ITEM':
      return {
        ...state,
        [action.key]: action.item,
      };

    default:
      return state;
  }
};

export const useAuth = () => {
  const [checkingAuth, setCheckingAuth] = useState(true);
  const navigator = useNavigate();
  const [userState, dispatch] = useReducer(userReducer, {
    isLoggedIn: false,
    token: null,
    tokenExpirationDate: null,
    userId: '',
    email: '',
    VATpayer: false,
    VATrate: 0,
    userAlias: '',
    avatar: '',
    theme: '',
    themes: [],
    language: '',
    languages: [],
    currency: '',
    currencies: [],
    units: [],
    services: [],
    invoiceLogo: '',
    taxNumber: '',
  });

  const { sendRequest } = useHttpClient();

  const changeContextItem = (key, item) => {
    dispatch({ type: 'CHANGE_ITEM', key, item });
    const localUserData = JSON.parse(localStorage.getItem('userData'));
    localUserData.user[key] = item;
    localStorage.setItem('userData', JSON.stringify({ ...localUserData }));
  };

  const login = useCallback(
    async (token, user) => {
      dispatch({
        type: 'LOGIN',
        token,
        user,
      });

      const getAppData = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/app/app-settings`
          );

          dispatch({ type: 'SET_APP_DATA', appData: responseData.message });

          localStorage.setItem(
            'appData',
            JSON.stringify({
              responseData,
            })
          );
        } catch (error) {}
      };
      getAppData();

      localStorage.setItem(
        'userData',
        JSON.stringify({
          token,
          user,
        })
      );
    },
    [sendRequest]
  );

  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT' });
    navigator('/', { replace: true });
    localStorage.removeItem('userData');
    localStorage.removeItem('appData');
  }, [navigator]);

  const localUserData = JSON.parse(localStorage.getItem('userData'));
  const token = localUserData?.token;
  const user = localUserData?.user;

  const { isExpired } = useJwt(token);

  useEffect(() => {
    if (localUserData && localUserData.token) {
      if (isExpired) return logout();
      login(token, user);
    }

    setCheckingAuth(false);
  }, [login, isExpired]);

  useEffect(() => {
    const getAppData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/app/app-settings`
        );

        dispatch({ type: 'SET_APP_DATA', appData: responseData.message });

        localStorage.setItem(
          'appData',
          JSON.stringify({
            responseData,
          })
        );
      } catch (error) {}
    };
    getAppData();
  }, []);

  return { userState, checkingAuth, login, logout, changeContextItem };
};
