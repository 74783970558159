export const roStrings = {
  translation: {
    yes: 'Da',
    no: 'Nu',
    or: 'sau',
    general: {
      noData: 'nu există date',
      pages: 'pagini',
      last7Days: 'ultimele 7 zile',
      issueInvoice: 'Emite factura',
      today: 'astăzi',
      edit: 'Editează',
      complete: 'Finalizează',
      remove: 'Șterge',
      invoicing: 'Facturare',
      language: 'Limba',
      company: 'Societate',
      pricing: 'Prețuri',
      alternateContacts: 'Contacte alternative',
      all: 'toate',
      status: 'Stare',
      generalInfo: 'General',
      accountInfo: 'Cont',
      balance: 'Sold',
      of: 'din',
      moreThan: 'peste',
      emailAlerts: 'Alerte e-mail',
    },
    filters: {
      all: 'toți',
      range: 'Interval',
      from: 'De la',
      to: 'Până la',
    },
    search: {
      placeholder: 'caută clienți, comenzi, facturi...',
      order: 'comandă',
      client: 'client',
      invoice: 'factură',
      byClient: 'caută după client...',
      byName: 'caută după nume...',
    },
    breadcrumbs: {
      dashboard: 'Panou de bord',
      queue: 'Comenzi în lucru',
      orders: 'Comenzi',
      clients: 'Clienți',
      statements: 'Evidență clienți',
      invoicing: 'Facturare',
      metrics: 'Date de lucru',
      profile: 'Profil',
      'new-invoice': 'Factură nouă',
      view: 'Detalii',
      help: 'Ajutor',
      reverse: 'Stornare',
      journal: 'Registrul Jurnal',
      inventory: 'Registrul Inventar',
      'tax-register': 'Registrul de Evidență Fiscală',
    },
    shortcuts: {
      addClient: 'Adaugă client',
      issueInvoice: 'Emite factură',
    },
    errors: {
      noClientAdded: 'Nu există clienți adăugați',
      addClient: 'Adaugă un client',
      fieldRequired: 'Acest câmp este obligatoriu',
    },
    headerMenu: {
      userSettings: 'Setări utilizator',
      changeAvatar: 'Schimbă avatarul',
      myAccount: 'Contul meu',
      settings: 'Setări',
      logout: 'Deconectare',
    },
    headerNotifications: {
      notifications: 'Notificări',
      youHave: 'Ai',
      newNotifications: 'notificări noi',
      seeAll: 'Vezi toate notificările',
    },
    actions: {
      delete: {
        expression: 'Șterge',
        confirmTitle: 'Confirmă ștergerea',
        confirmMessage: 'Sigur dorești să ștergi acest articol?',
        confirmBtn: 'Șterge',
      },
      complete: {
        expression: 'Finalizează',
        confirmTitle: 'Confirmați valorile curente?',
        confirmMessage: 'Comanda va fi finalizată cu aceste valori finale',
        confirmBtn: 'Finalizează',
      },
    },
    appServices: {
      translation: 'traducere',
      proofreading: 'corectură',
      postEditing: 'post-editare',
    },
    appUnits: {
      '2000cw/s': 'caractere cu spații',
      '1800cw/os': 'caractere fără spații',
      word: 'cuvânt',
      '300w': 'cuvinte',
      forms: {
        '2000cw/s': '2000 caractere cu spații',
        '1800cw/os': '1800 caractere fără spații',
        word: 'cuvânt',
        '300w': '300 cuvinte',
      },
      short: {
        '2000cw/s': '2000ccs',
        '1800cw/os': '1800cfs',
        word: 'cuv.',
        '300w': '300cuv.',
      },
      mu: {
        '2000cw/s': 'ccs',
        '1800cw/os': 'cfs',
        word: 'cuv.',
        '300w': 'cuv.',
      },
    },
    appThemes: {
      default: 'Implicit',
      light: 'Luminos',
      dar: 'Întunecat',
    },

    options: {
      details: 'Detalii',
      edit: 'Editează',
      remove: 'Șterge',
      send: 'Trimite',
      save: 'Salvează',
      cancel: 'Anulează',
    },

    forms: {
      general: 'general',
      invoicing: 'facturare',
      company: 'societate',
    },

    fileUpload: {
      failedToSign: 'Nu s-a putut autoriza încărcarea.',
      noFile: 'Nu ați selectat un fișier!',
      uploadFailed: 'Fișierul nu a putut fi încărcat.',
      sizeExceeded:
        'Fișierul trebuie să fie o imagine (jpg, png) și să aibă maxim 60kb.',
    },

    header: {
      settings: 'setări',
      logOut: 'deconectare',
    },

    headers: {
      dashboard: 'Panou de bord',
      activity: 'Activitate',
      accounting: 'Contabilitate',
      accountingJournal: 'Registrul Jurnal',
      taxRecordRegister: 'Registrul de Evidență Fiscală',
      inventory: 'Registrul Inventar',
      orders: 'Comenzi',
      user: 'Utilizator',
      list: 'Listă',
      queue: 'Comenzi în lucru',
      issue: 'Emite',
      clients: 'Clienți',
      statements: 'Evidențe lucrări',
      statement: 'Evidență client',
      invoicing: 'Facturi',
      metrics: 'Statistici',
      profile: 'Profil',
    },

    accounting: {
      totalsForPeriod: 'Total perioadă',
      downloadTransactionsFile: 'Jurnal încasări și plăți',
      downloadInventory: 'Registrul Inventar',
      downloadTaxRegister: 'Registrul de evidență fiscală',
      year: 'Anul',
      type: 'Tip',
      all: 'Toate',
      method: 'Metoda',
      amount: 'Suma',
      description: 'Descriere',
      currency: 'Moneda',
      receipt: 'Încasare',
      newPayment: 'Plată nouă',
      newReceipt: 'Încasare nouă',
      editPayment: 'Editează plata',
      editReceipt: 'Editează încasarea',
      deletePayment: 'Șterge plata',
      deleteReceipt: 'Șterge încasarea',
      receipts: 'Încasări',
      payment: 'Plată',
      payments: 'Plăți',
      date: 'Data',
      typeOfOperation: 'Felul operațiunii',
      document: 'Document',
      bank: 'Bancă',
      cash: 'Numerar',
      itno: 'Nr. crt.',
      po: 'ordin de plată',
      edit: 'Editează',
      delete: 'Șterge',
      value: 'Valoare',
      deductible: 'Deductibilitate',
      percent: 'Procent',
      fullDeductible: 'Integral',
      partialDeductible: 'Parțial',
      notDeductible: 'Nedeductibil',
      receivables: 'Sold creditor',
      payables: 'Sold debitor',
      assets: 'Active',
      taxes: 'Taxe',
      item: 'Element',
      items: 'Elemente',
      designation: 'Denumire',
      healthInsurance: 'CASS',
      socialSecurity: 'CAS',
      incomeTax: 'Impozit pe venit',
      editItem: 'Editează articol',
      deleteItem: 'Șterge articol',
      add: {
        payables: 'Adaugă debit',
        assets: 'Adaugă obiect inventar',
        taxes: 'Adaugă taxă',
      },
      grossIncome: 'Venit brut',
      cashed: 'încasat',
      fullyDeductibleExpenses: 'Cheltuieli deductible integral',
      partiallyDeductibleExpenses: 'Cheltuieli deductible parțial',
      nonDeductibleExpenses: 'Cheltuieli nedeductible',
      netIncome: 'Venit net',
    },

    auth: {
      authTitle: 'Autentificare',
      authEmail: 'Adresa de e-mail',
      authPass: 'Parola',
      loginBtn: 'Conectează-te',
      loginEmailErrorText: 'Adresa introdusă nu este validă',
      loginPasswordErrorText: 'Nu ați introdus o parolă',
      registerBtn: 'Înregistrare',
      registerName: 'Nume',
      registerNameErrorText: 'Trebuie să introduceți un nume',
      registerLanguage: 'Limba preferată',
      registerLanguageErrorText: 'Trebuie să selectați o limbă',
      registerCurrency: 'Moneda preferată',
      registerCurrencyErrorText: 'Trebuie să selectați o monedă',
      registerPasswordErrorText:
        'Parola trebuie să conțină cel puțin 5 caractere',
      repeatPass: 'Reintroduceți parola',
      recoverEmail: 'Adresa de email folosită la înregistrare',
      recoverEmailErrorText: 'Adresa de email introdusă nu este validă',
      resetBtn: 'Resetare',
      sendRecoverPasswordLink: 'Trimite link de resetare',
      passwordMismatch: 'Cele două parole nu se potrivesc',
      resetEmailErrorText: 'Trebuie să introduceți o adresă de email validă',
      resetPasswordErrorText: 'Parola trebuie să aibă minim 5 charactere',
    },
    reset: {
      resetPassword: 'Resetează parola',
      confirmEmail: 'Confirmă adresa de e-mail',
      newPassword: 'Parola nouă',
      confirmNewPassword: 'Confirmă parola nouă',
    },
    navigation: {
      dashboard: 'Panou de bord',
      pendingOrders: 'Comenzi în lucru',
      clients: 'Clienți',
      clientStatements: 'Evidență clienți',
      invoicing: 'Facturi',
      metrics: 'Date de lucru',
      profile: 'Profil',
    },
    profile: {
      title: 'Profil utilizator',
      accountType: 'Tip cont',
      subscription: 'Abonament',
      subscriptionFree: 'Gratuit',
      subscriptionPremium: 'Premium',
      timeZone: 'Fus orar',
      update: 'Actualizează',
      name: 'Denumirea societății',
      profileName: 'Nume de profil',
      currentPassword: 'Parola actuală',
      newPassword: 'Parola nouă',
      confirmPassword: 'Confirmă parola',
      passwordRequirements: 'Cerințe privind parola',
      minChars: 'Minim 6 caractere',
      currency: 'Moneda',
      registeredOffice: 'Sediul',
      registrationNumber: 'Număr de înregistrare',
      taxNumber: 'Cod fiscal',
      bank: 'Banca',
      iban: 'IBAN',
      VATpayer: 'Plătitor TVA',
      VATrate: 'Cota TVA',
      invoiceDefaultDue: 'Scadență implicită facturi (zile)',
      invoiceStartNumber: 'Numărul de început al facturilor',
      invoicePrefix: 'Seria facturilor',
      invoiceNotes: 'Note prezente în facturi',
      bankAccounts: 'Conturi bancare',
      receipts: 'Chitanțe',
      invoiceLogo: 'Sigla prezentă în facturi și mesaje',
      invoiceLogoNote: 'Dacă nu există o siglă, se va folosi sigla Zent',
      emailReminders: 'Alerte e-mail',
      sendEmail: 'Trimite e-mail',
      invoiceAlerts: 'Alerte facturi către clienți',
      emailRemainderClientNote:
        'Valabil numai pentru clienții care au o adresă de e-mail în factură. Veți primi o copie a acestor mesaje.',
      beforeMaturity: 'Cu o zi înainte de scadență',
      twoDays: 'La două zile după scadență',
      fourDays: 'La patru zile după scadență',
      sixDays: 'La șase zile după scadență',
    },
    dashboard: {
      greeting: 'Bine ai revenit',
      today: 'Astăzi',
      youHave: 'ai',
      ordersToComplete: 'comenzi de finalizat',
      inTotal: 'În total',
      thereAre: 'sunt',
      pendingOrders: 'comenzi în lucru',
      convertedValues: 'Valorile în valută sunt convertite în moneda aleasă',
      convertedUnits:
        'Unitățile exprimate în caractere sau cuvinte sunt convertite la echivalentul unei pagini.',
      units: 'unități',
      recentWork: 'Cantități realizate',
      recentMoney: 'Valori realizate',
      pages: 'pagini',
      ordersToday: 'Comenzi de predat astăzi',
      invoicesThisWeek: 'Facturi scadente curând',
      goToPendingOrders: 'mergi la comenzi în lucru',
      goToInvoices: 'mergi la facturi',
      invoicesOutstanding: 'Facturi restante',
      noOrders: 'Nu sunt comenzi de predat astăzi',
      noInvoices: 'Nu există facturi scadente săptămâna aceasta',
      outstanding: 'Restante',
      soonDue: 'Scadente',
      notes: 'Note',
      activity: 'Activitate',
      invoices: 'Facturi',
      noSoonDueInvoices: 'Nu există facturi scadente în această săptămână',
      noOutstandingInvoices: 'Nu există facturi restante',
      noOrdersToday: 'Nu există comenzi de predat astăzi',
      add: 'adaugă note...',
      totalOrders: 'Total comenzi',
      clientStatistics: 'Statistici clienți',
      chart: {
        yearChartTitle: 'Evoluția câștigurilor lunare',
        earnings: 'câștiguri',
      },
    },
    pending: {
      title: 'Organizator comenzi în lucru',
      addBtn: 'Adaugă comandă nouă',
      noOrders: 'Nu aveți comenzi în lucru',
    },
    clients: {
      header: 'Registru clienți',
      addBtn: 'Adaugă client nou',
      noClients: 'Nu aveți clienți adăugați',
    },
    orders: {
      service: 'Tip serviciu',
      description: 'Description',
      client: 'Client',
      reference: 'Referință',
      receivedDate: 'Data primirii',
      deadline: 'Termen',
      currency: 'Moneda',
      status: 'Stare',
      changeDeadline: 'Schimbă termenul',
      deliveredDate: 'Data predării',
      rate: 'Preț unitar',
      editRate: 'Editează prețul unitar',
      mu: 'UM',
      count: 'Cantitate',
      estimatedCount: 'Cantitate estimată',
      estimatedTotal: 'Total estimat',
      editCount: 'Editează volumul',
      finalCount: 'Volum final',
      total: 'Total',
      notes: 'Note',
      sorting: {
        all: 'toate',
        today: 'termen astăzi',
        tomorrow: 'termen mâine',
        thisWeek: 'pentru săptămâna curentă',
        pending: 'în lucru',
        completed: 'finalizate',
        invoiced: 'facturate',
        notInvoiced: 'nefacturate',
      },
      statuses: {
        queue: 'În lucru',
        completed: 'Finalizat',
        invoiced: 'Facturat',
      },
    },
    items: {
      add: 'Adaugă articol',
      edit: 'Editează articol',
    },
    client: {
      name: 'Nume',
      phone: 'Telefon',
      mobile: 'Mobil',
      email: 'E-mail',
      language: 'Limba',
      rate: 'Preț unitar',
      selectUnit: 'selectează unitatea...',
      selectCurrency: 'selectează moneda...',
      taxNumber: 'Cod fiscal',
      currency: 'Moneda',
      mu: 'Unitate de măsură',
      registeredOffice: 'Sediul social',
      mailingAddress: 'Adresa de corespondență',
      registrationNumber: 'Număr de înregistrare',
      bank: 'Banca',
      iban: 'IBAN',
      vatPayer: 'Plătitor TVA',
      representative: 'Reprezentant',
      invoiceDue: 'Scadență facturi (zile)',
      invoiceNotes: 'Note incluse în facturi',
      decimalPoints: 'Puncte zecimale',
      translationRate: 'Preț traducere',
      proofreadingRate: 'Preț corectură',
      posteditingRate: 'Preț post-editare',
      notes: 'Note',
      alternateContacts: 'Contacte alternative',
      primaryContact: 'Contact principal',
      secondaryContact: 'Contact secundar',
      addedAt: 'Data adăugării',
      totalInvoiced: 'Valoare facturi emise',
      options: {
        changeAvatar: 'Schimbă imaginea',
        changeLanguage: 'Schimbă limba',
        viewOrders: 'Vezi istoric comenzi',
        invoiceStatement: 'Facturează din evidență',
        invoiceSeparate: 'Factură separată',
      },
    },
    statements: {
      title: 'Evidență clienți',
      searchPlaceholder: 'caută după nume...',
      lastInvoicing: 'Ultima factură emisă la',
      noInvoice: 'nu există facturi emise',
      prevBalance: 'Sold anterior',
      total: 'Total',
      totalBalance: 'Total sold',
      currentBalance: 'Sold curent',
      filterAll: 'Toți clienții',
      filterPositiveBalance: 'Sold pozitiv',
      filterNegativeBalance: 'Sold negativ',
      noResults: 'Nu există rezultate',
      uninvoicedAmount: 'Valoare nefacturată',
      statement: {
        title: 'Evidență lucrări',
        receivedDelivered: 'Primit/predat',
        invoicing: 'Facturare',
      },
    },
    invoicing: {
      title: 'Facturi',
      invoiceData: 'Date factură',
      addItems: 'Adaugă articole',
      addItem: 'Adaugă articol',
      addDiscount: 'Adaugă reducere',
      searchPlaceholder: 'caută după nume client...',
      listDetailedOrders: 'Listează comenzi detaliate',
      invFilter: 'Toate',
      invFilterPos: 'Sold pozitiv',
      invFilterNeg: 'Sold negativ',
      period: 'Perioada',
      invoiceName: 'Factură',
      statusIssued: 'emisă',
      statusCashed: 'încasată',
      statusDelayed: 'restantă',
      statusReversed: 'stornată',
      options: {
        view: 'Vizualizează',
        send: 'Trimite',
        cash: 'Încasează',
        edit: 'Editează',
        delete: 'Șterge',
        reverse: 'Stornează',
        cancel: 'Anulează',
        download: 'Descarcă',
      },
      noPrefixSet: 'Nu ați setat o serie pentru facturi',
      noNameSet: 'Nu ați setat denumirea PFA/societate',
      noTaxNumberSet: 'Nu ați setat codul fiscal',
      invoiceInvalid:
        'Există una sau mai multe setări invalide. Acestea sunt evidențiate în panoul alăturat.',
      selectClient: 'selectează un client...',
      goToProfile: 'Mergi la profil',
      events: {
        issued: 'Factura a fost emisă',
        paid: 'Factura a fost platită',
      },
      invoice: {
        title: 'Factura',
        prefix: 'serie',
        number: 'nr.',
        issuedDate: 'Data emiterii',
        maturity: 'Data scadentă',
        status: 'Stare',
        supplier: 'Furnizor',
        client: 'Client',
        contact: 'Contact',
        addDiscount: 'Adaugă reducere',
        addDiscountAmount: 'Valoare',
        addDiscountPercentage: 'Procent',
        items: 'Articole',
        bank: 'Banca',
        discount: 'Reducere',
        no: 'Nr.',
        typeRef: 'Serviciu/Referință',
        qty: 'Cantitate',
        mu: 'UM',
        muLong: 'unitate de măsură',
        rate: 'Preț unitar',
        vat: 'TVA',
        valueWithoutVAT: 'Valoare fără TVA',
        valueWithVAT: 'Valoare cu TVA',
        value: 'Valoare',
        reverseHeading: 'storno factura',
        toPay: 'Total de plată',
        subTotal: 'Sub-total',
        clientBalance: 'Sold client',
        remainder: 'Sold',
        description: 'Descriere',
        itemsList: 'Articole adăugate',
        sendError: 'Nu ați adăugat o adresă de email acestui client',
        notes: 'Note',
        deduction: 'Deducere',
        total: 'Total',
        paymentInfo: 'Modalități de plată',
        bankAccounts: 'Conturi bancare',
        translationServices: 'Servicii de traducere',
        dateCashed: 'Data plății',
        cashedAmount: 'Suma plătită',
        receipt: 'Document de plată',
        notPaid: 'Factura nu este plătită',
      },
      view: {
        details: 'Detalii',
        payment: 'Plată',
      },
    },
    receipt: {
      title: 'Chitanță',
      receiptPrefix: 'Seria chitanțelor',
      receiptStartNumber: 'Numărul de început al chitanțelor',
      errorNoSet: 'Nu s-a setat serie și număr',
    },
    metrics: {
      title: 'Date de lucru',
      today: 'Astăzi',
      yesterday: 'Ieri',
      currentWeek: 'Săptămâna curentă',
      lastWeek: 'Săptămâna trecută',
      currentMonth: 'Luna curentă',
      lastMonth: 'Luna trecută',
      last7Days: 'Venituri ultimele 7 zile',
      earnings: 'Câștiguri',
      monthlyIncome: 'Venit lunar',
      dayBefore: 'raportat la ziua anterioară',
      weekBefore: 'raportat la săptămâna anterioară',
      monthBefore: 'raportat la luna anterioară',
    },
    buttons: {
      openBtn: 'Deschide',
      backBtn: 'Înapoi',
      addBtn: 'Adaugă',
      deleteBtn: 'Șterge',
      editBtn: 'Editează',
      saveBtn: 'Salvează',
      completeBtn: 'Finalizează',
      confirmBtn: 'Confirmă',
      invoiceBtn: 'Facturează',
      exportBtn: 'Exportă PDF',
      sendBtn: 'Trimite',
      sendToClient: 'Trimite către client',
      issue: 'Emite',
      issueReverseBtn: 'Emite storno',
      cashBtn: 'Încasează',
      resetPasswordBtn: 'Resetează parola',
      optionsBtn: 'Opțiuni',
      cancelBtn: 'Anulează',
      detailsBtn: 'Detalii',
      resetBtn: 'Resetează',
    },
    modals: {
      successHeader: 'Succes',
      errorHeader: 'Eroare',
      user: {
        settings: {
          header: 'Setări de aplicație',
          language: 'Limba de afișare',
          currency: 'Moneda preferată',
          theme: 'Temă',

          accountType: 'Tip utilizator: administrator',
        },
        personalData: {
          header: 'Actualizează informațiile personale',
          name: 'Nume',
          nameErrorText: 'Trebuie să specificați un nume sau alias',
          email: 'Email',
          contact: 'Telefon',
        },
        professionalData: {
          header: 'Actualizează informațiile profesionale',
          name: 'Denumire PFA/societate',
          registeredOffice: 'Sediul social',
          registrationNumber: 'Număr de autorizație/înregistrare',
          taxNumber: 'Cod fiscal',
          VATpayer: 'Plătitor TVA',
          VATrate: 'Cota TVA',
        },
        invoicingData: {
          header: 'Actualizează informațiile de facturare',
          prefix: 'Seria facturilor',
          number: 'Numărul de început al facturilor',
          defaultMaturity: 'Scadența implicită (zile)',
          bank: 'Banca',
          iban: 'IBAN',
          invoiceMessage: 'Mesaj la trimiterea facturilor',
          statementMessage: 'Mesaj la trimiterea evidențelor',
          notes: 'Include următoarele mențiuni în facturi',
        },
      },
      orders: {
        addOrder: {
          header: 'Adaugă comandă nouă',
          selectClient: 'selectează un client...',
          serviceErrorText: 'Trebuie să selectați un tip de serviciu',
          clientErrorText: 'Trebuie să selectați un client',
          receivedDateErrorText: 'Trebuie să selectați data primirii',
          deliveredDateErrorText: 'Trebuie să selectați data predării',
          deadlineErrorText: 'Trebuie să selectați un termen',
          rateErrorText: 'Trebuie să specificați un preț unitar',
          muErrorText: 'Trebuie să selectați unitatea de măsură',
          estimatedCountErrorText: 'Trebuie să specificați un volum estimat',
        },
        completeOrder: {
          header: 'Finalizează comanda',
          finalCountErrorText: 'Nu ați introdus cantitatea finală',
        },
        editOrder: {
          header: 'Editează comanda',
          changeDeadline: 'Schimbă termenul',
        },
        deleteOrder: {
          header: 'Șterge articol',
          confirmationMsg: 'Sigur dorești să ștergi acest articol?',
        },
        orderActions: {
          header: 'Detalii comandă',
        },
      },
      clients: {
        viewClient: {
          header: 'Date client',
          footer: 'Data adăugării',
        },
        addClient: {
          onlyRoCompanies:
            'Datele se obțin automat numai pentru societăți înregistrate în România',
          header: 'Adaugă client nou',
          taxNumberErrorText: 'Trebuie să introduceți codul fiscal',
          nameErrorText: 'Trebuie să introduceți numele',
          currencyErrorText: 'Trebuie să selectați o monedă',
          muErrorText: 'Trebuie să selectați o unitate de măsură',
          languageErrorText: 'Trebuie să selectați o limbă',
        },
        editClient: {
          header: 'Editează clientul',
        },
        deleteClient: {
          header: 'Șterge client',
          prompt: 'Sigur dorești să ștergi acest client?',
          subtitle:
            'Toate comenzile nefacturate ale acestui client vor fi șterse permanent',
        },
      },
      statement: {
        send: {
          header: 'Trimite evidența lucrărilor',
          message: 'Corpul mesajului',
          client: 'Client',
          emailErrorText: 'Nu ați introdus o adresă de email',
        },
      },
      invoicing: {
        send: {
          header: 'Trimite factura',
          message: 'Corpul mesajului',
          client: 'Client',
          emailErrorText: 'Nu ați introdus o adresă de email',
          includeStatement: 'Include evidența detaliată',
        },
        cash: {
          header: 'Încasează factura',
          issuedTo: 'emisă către',
          cashedVal: 'Valoare încasată',
          docType: 'Cu document tip/număr',
          date: 'Data încasării',
          cashedValErrorText: 'Trebuie să introduceți o sumă',
          docTypeErrorText: 'Trebuie să introduceți un tip/număr de document',
          dateErrorText: 'Nu ați selectat o dată',
        },
        edit: {
          header: 'Editează factura',
          issuedTo: 'emisă către',
          jobRef: 'Serviciu/Ref.',
          options: 'Opțiuni',
          important: 'important',
          note1:
            'Modificarea articolelor individuale recalculează totalul, însă nu afectează restul de plătit (AJUSTĂRI).',
          note2:
            'Modificarea totalului dezactivează optiunile articolelor individuale și afectează restul de plătit (ÎNCASARE PARȚIALĂ).',
        },
        cancel: {
          header: 'Anulează factura',
          keepNote: 'Păstrează articolele',
          note: 'Următoarele articole incluse în factură pot fi șterse sau pot fi păstrate pentru facturare ulterioară',
        },
      },
    },

    charts: {
      pages: 'pagini',
      perMonthLabel: 'Lucrări finalizate pe lună',
      per7DaysLabel: 'Venituri ultimele 7 zile',
      last7Days: 'ultimele 7 zile',
      today: 'astăzi',
      thisWeek: 'săptămâna curentă',
      thisMonth: 'luna curentă',
      monthlyLabel: 'Venituri lunare',
      thisMonthLabel: 'Lucrări finalizate în luna curentă',
      top5Clients: 'Top 5 clienți după valoarea comenzilor',
      gains: 'Câștiguri',
      totalIssued: 'Total emis',
    },
    months: {
      Jan: 'Ianuarie',
      Feb: 'Februarie',
      Mar: 'Martie',
      Apr: 'Aprilie',
      May: 'Mai',
      Jun: 'Iunie',
      Jul: 'Iulie',
      Aug: 'August',
      Sep: 'Septembrie',
      Oct: 'Octombrie',
      Nov: 'Noiembrie',
      Dec: 'Decembrie',
    },
    signature: 'Document generat cu ZenT-Freelance',
  },
};
