import React from 'react';

import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Zoom from '@mui/material/Zoom';

import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in="props.open" ref={ref} {...props} />;
});

const DialogComponent = ({
  open,
  onClose,
  component,
  maxWidth,
  onSubmit,
  title,
  content,
  actions,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      component={component}
      maxWidth={maxWidth}
      fullWidth
      onSubmit={onSubmit}
      keepMounted
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ background: theme.palette.grey[200], mb: '2rem' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
