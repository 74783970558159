import React, { useState, useEffect } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import List from '@mui/material/List';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import { openedMixin, closedMixin } from './drawerMixins';

import DrawerHeader from './DrawerHeader';

const drawerWidth = 280;

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Drawer = (props) => {
  const matchesSm = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();

  const [isDrawerOpen, setIsDrawerOpen] = useState(props.open);

  useEffect(() => {
    let timeoutId;

    if (props.open) {
      timeoutId = setTimeout(() => {
        setIsDrawerOpen(true);
      }, 300); // Adjust the timeout value as needed
    } else {
      setIsDrawerOpen(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.open]);

  return (
    <StyledDrawer variant="permanent" open={props.open}>
      <DrawerHeader
        open={props.open}
        handleDrawerClose={props.handleDrawerClose}
      />

      <List>
        {[
          {
            id: 0,
            title: t('headers.dashboard'),
            items: [
              {
                id: 0,
                label: t('headers.activity'),
                icon: (item) => (
                  <SummarizeOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/main',
              },
            ],
          },
          {
            id: 1,
            title: t('headers.orders'),
            items: [
              {
                id: 0,
                label: t('headers.list'),
                icon: (item) => (
                  <FormatListBulletedIcon fontSize={item.fontSize} />
                ),
                route: '/orders',
              },
              {
                id: 1,
                label: t('headers.queue'),
                icon: (item) => (
                  <PendingActionsOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/queue',
              },
            ],
          },
          {
            id: 2,
            title: t('headers.clients'),
            items: [
              {
                id: 0,
                label: t('headers.list'),
                icon: (item) => (
                  <FormatListBulletedIcon fontSize={item.fontSize} />
                ),
                route: '/clients',
              },
              {
                id: 1,
                label: t('headers.statements'),
                icon: (item) => (
                  <AccountBalanceOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/statements',
              },
            ],
          },
          {
            id: 4,
            title: t('headers.invoicing'),
            items: [
              {
                id: 0,
                label: t('headers.list'),
                icon: (item) => (
                  <FormatListBulletedIcon fontSize={item.fontSize} />
                ),
                route: '/invoicing',
              },
              {
                id: 0,
                label: t('headers.issue'),
                icon: (item) => (
                  <CreditCardOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/invoicing/new-invoice',
              },
            ],
          },
          {
            id: 5,
            title: t('headers.accounting'),
            items: [
              {
                id: 0,
                label: t('headers.accountingJournal'),
                icon: (item) => (
                  <PointOfSaleOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/journal',
              },
              {
                id: 1,
                label: t('headers.inventory'),
                icon: (item) => (
                  <Inventory2OutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/inventory',
              },
              {
                id: 1,
                label: t('headers.taxRecordRegister'),
                icon: (item) => <PaidOutlinedIcon fontSize={item.fontSize} />,
                route: '/tax-register',
              },
            ],
          },
          {
            id: 6,
            title: t('headers.user'),
            items: [
              {
                id: 0,
                label: t('headers.profile'),
                icon: (item) => (
                  <AccountCircleOutlinedIcon fontSize={item.fontSize} />
                ),
                route: '/profile',
              },
            ],
          },
        ].map((section, index) => (
          <React.Fragment key={index}>
            <ListItem
              sx={{
                justifyContent: props.open ? 'flex-start' : 'center',
                pb: 0,
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  display: 'block',
                  margin: props.open ? '3rem 0 0 1rem' : '3rem 0 0 0',
                  fontWeight: 700,
                  fontSize: props.open ? '1.2rem' : '8px',
                }}
              >
                {props.open ? section.title : section.title.split(' ')[0]}
              </Typography>
            </ListItem>

            <List disablePadding>
              {section.items.map((item, index) => (
                <ListItem
                  key={index}
                  component={NavLink}
                  to={item.route}
                  sx={{
                    display: 'block',
                    transition: 'all 0.1s',
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: 'common.black',
                  }}
                  onClick={matchesSm ? null : props.handleDrawerClose}
                >
                  <ListItemButton
                    disableRipple
                    sx={{
                      justifyContent: props.open ? 'initial' : 'center',
                      background:
                        location.pathname === item.route ? '#d5eaff' : '',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      margin: isDrawerOpen ? 0 : '1rem 0',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '.MuiSvgIcon-root': {
                          fill: theme.palette.common.black,
                        },
                      }}
                    >
                      {item.icon({ fontSize: 'small' })}
                    </ListItemIcon>

                    <div
                      sx={{
                        flex: 1,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        transition: 'none', // Disable transition for the wrapping div
                      }}
                    >
                      {isDrawerOpen && (
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontSize: '1.4rem',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {item.label}
                            </Typography>
                          }
                        />
                      )}
                    </div>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default Drawer;
