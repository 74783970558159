import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { AuthContext } from '../../../context/auth-context';

import { useUploadImage } from '../../../hooks/useUploadImage';

import { headerStyles } from './styles';

const ProfileMenu = (props) => {
  const { userAlias, email, logout } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigator = useNavigate();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const openMenu = Boolean(menuAnchor);

  const openMenuHandler = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const closeMenuHandler = () => {
    setMenuAnchor(null);
  };

  const {
    isLoading,
    previewAvatar,
    filePicker,
    getFilePicker,
    filePickerHandler,
  } = useUploadImage('user');

  return (
    <Box>
      <Tooltip title={t('headerMenu.userSettings')}>
        <IconButton onClick={openMenuHandler} size="small" sx={{ ml: 2 }}>
          <Avatar id="preview" variant="contained" src={previewAvatar} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={menuAnchor}
        id="account-menu"
        open={openMenu}
        onClose={closeMenuHandler}
        PaperProps={{
          elevation: 0,
          sx: headerStyles.menu,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem selected disableRipple>
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ padding: '2rem' }}
          >
            <Grid item onClick={getFilePicker}>
              <input
                style={{ display: 'none' }}
                type="file"
                id="file-input"
                ref={filePicker}
                onChange={filePickerHandler}
              />
              <input
                type="hidden"
                id="avatar-url"
                name="avatar-url"
                value={previewAvatar}
              />
              <Tooltip title={t('headerMenu.changeAvatar')}>
                <Avatar
                  id="preview"
                  variant="contained"
                  src={previewAvatar}
                  sx={headerStyles.avatar}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography variant="body1">{userAlias}</Typography>
              <Typography variant="caption">{email}</Typography>
            </Grid>
          </Grid>
        </MenuItem>

        <Box sx={headerStyles.menuItems}>
          {/* <MenuItem
            onClick={() => {
              navigator('/profile');
              closeMenuHandler();
            }}
          >
            <Avatar /> {t('headerMenu.myAccount')}
          </MenuItem> */}
          <Divider />

          <MenuItem
            onClick={() => {
              props.handleOpenSettings();
              closeMenuHandler();
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t('headerMenu.settings')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              logout();
              closeMenuHandler();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t('headerMenu.logout')}
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
