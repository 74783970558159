export const enStrings = {
  translation: {
    yes: 'Yes',
    no: 'No',
    sau: 'or',
    general: {
      noData: 'no data to display',
      pages: 'pages',
      last7Days: 'last 7 days',
      today: 'today',
      edit: 'Edit',
      complete: 'Complete',
      remove: 'Remove',
      invoicing: 'Invoicing',
      issueInvoice: 'Issue invoice',
      client: 'Client',
      language: 'Language',
      company: 'Company',
      pricing: 'Prices',
      all: 'all',
      status: 'Status',
      alternateContacts: 'Alternate contacts',
      generalInfo: 'General information',
      accountInfo: 'Account information',
      balance: 'Balance',
      of: 'of',
      moreThan: 'more than',
      emailAlerts: 'E-mail reminders',
    },
    filters: {
      all: 'all',
      range: 'Range',
      from: 'From',
      to: 'To',
    },
    search: {
      placeholder: 'search for clients, orders, invoices...',
      order: 'order',
      client: 'client',
      invoice: 'invoice',
      byClient: 'search by client...',
      byName: 'search by name...',
    },
    breadcrumbs: {
      dashboard: 'Dashboard',
      queue: 'Pending',
      orders: 'Orders',
      clients: 'Clients',
      statements: 'Work statement',
      invoicing: 'Invoicing',
      metrics: 'Metrics',
      profile: 'Profile',
      'new-invoice': 'New invoice',
      view: 'Details',
      help: 'Help',
      reverse: 'Reverse',
      journal: 'Accounting Journal',
      inventory: 'Inventory Book',
      'tax-register': 'Tax Record Register',
    },
    shortcuts: {
      addClient: 'Add client',
      issueInvoice: 'Issue invoice',
    },
    errors: {
      noClientAdded: 'You have no client added',
      addClient: 'Add a client',
      fieldRequired: 'This field is required',
    },
    headerNotifications: {
      notifications: 'Notifications',
      youHave: 'You have',
      newNotifications: 'new notifications',
      seeAll: 'See all notifications',
    },
    headerMenu: {
      userSettings: 'User settings',
      changeAvatar: 'Change avatar',
      myAccount: 'My Account',
      settings: 'Settings',
      logout: 'Log out',
    },
    actions: {
      details: {
        expression: 'Details',
      },
      delete: {
        expression: 'Delete',
        confirmTitle: 'Confirm delete',
        confirmMessage: 'Are your sure you want to delete this item?',
        confirmBtn: 'Remove',
      },
      complete: {
        expression: 'Complete',
        confirmTitle: 'Confirm current data?',
        confirmMessage:
          'The order will be marked complete with these final figures',
        confirmBtn: 'Complete',
      },
    },
    appServices: {
      translation: 'translation',
      proofreading: 'proofreading',
      postEditing: 'post-editing',
    },

    appUnits: {
      '2000cw/s': 'characters w/ spaces',
      '1800cw/os': 'characters w/o spaces',
      word: 'word',
      '300w': 'words',
      forms: {
        '2000cw/s': '2000 characters w/ spaces',
        '1800cw/os': '1800 characters w/o spaces',
        word: 'word',
        '300w': '300 words',
      },
      short: {
        '2000cw/s': '2000ch',
        '1800cw/os': '1800ch',
        word: 'word',
        '300w': '300w',
      },
      mu: {
        '2000cw/s': 'cws',
        '1800cw/os': 'cwos',
        word: 'w.',
        '300w': 'w.',
      },
    },
    appThemes: {
      default: 'Default',
      light: 'Light',
      dar: 'Dark',
    },

    options: {
      details: 'Details',
      edit: 'Edit',
      remove: 'Remove',
      send: 'Send',
      save: 'Save',
      cancel: 'Cancel',
    },

    forms: {
      general: 'general',
      invoicing: 'invoicing',
      company: 'company',
    },

    fileUpload: {
      failedToSign: 'Could not get signed URL.',
      noFile: 'No file selected!',
      uploadFailed: 'Could not upload file.',
      sizeExceeded:
        'The file must be an image (jpg, png) and maximum 60kb in size.',
    },

    header: {
      settings: 'settings',
      logOut: 'log out',
    },

    headers: {
      dashboard: 'Dashboard',
      activity: 'Activity',
      accounting: 'Accounting',
      accountingJournal: 'Accounting Journal',
      taxRecordRegister: 'Tax Record Register',
      inventory: 'Inventory Book',
      orders: 'Orders',
      user: 'User',
      list: 'List',
      queue: 'Orders in progress',
      issue: 'New',
      clients: 'Clients',
      statement: 'Work statement',
      statements: 'Work statements',
      invoicing: 'Invoices',
      metrics: 'Metrics',
      profile: 'Profile',
    },

    accounting: {
      totalsForPeriod: 'Totals for the period',
      downloadTransactionsFile: 'Accounting Journal',
      downloadInventory: 'Inventory Book',
      downloadTaxRegister: 'Tax Record Register',
      year: 'Year',
      type: 'Type',
      all: 'All',
      method: 'Method',
      amount: 'Amount',
      description: 'Description',
      currency: 'Currency',
      receipt: 'Receipt',
      receipts: 'Receipts',
      payments: 'Payments',
      newReceipt: 'New receipt',
      newPayment: 'New payment',
      editReceipt: 'Edit receipt',
      editPayment: 'Edit payment',
      deleteReceipt: 'Delete receipt',
      deletePayment: 'Delete payment',
      payment: 'Payment',
      date: 'Date',
      typeOfOperation: 'Type of operation',
      document: 'Document',
      bank: 'Bank',
      cash: 'Cash',
      itno: 'It. no.',
      po: 'payment order',
      edit: 'Edit',
      delete: 'Delete',
      value: 'Value',
      deductible: 'Deductibility',
      percent: 'Percent',
      fullDeductible: 'Full',
      partialDeductible: 'Partial',
      notDeductible: 'Not deductible',
      receivables: 'Credit Balance',
      payables: 'Debit Balance',
      assets: 'Assets',
      taxes: 'Taxes',
      item: 'Item',
      items: 'Items',
      designation: 'Designation',
      healthInsurance: 'Health insurance',
      socialSecurity: 'Social security',
      incomeTax: 'Income tax',
      editItem: 'Edit item',
      deleteItem: 'Delete item',
      add: {
        payables: 'Add payable',
        assets: 'Add asset',
        taxes: 'Add tax',
      },
      grossIncome: 'Gross income',
      cashed: 'cashed',
      fullyDeductibleExpenses: 'Fully deductible expenses',
      partiallyDeductibleExpenses: 'Partially deductible expenses',
      nonDeductibleExpenses: 'Non-deductible expenses',
      netIncome: 'Net income',
    },

    auth: {
      authTitle: 'Log in',
      authEmail: 'Your e-mail address',
      authPass: 'Password',
      loginBtn: 'Log in',
      loginEmailErrorText: "The email address you've entered is not valid",
      loginPasswordErrorText: 'You need to enter a password',
      registerBtn: 'Register',
      registerName: 'Your name',
      registerNameErrorText: 'You need to enter a name',
      registerLanguage: 'Preferred language',
      registerLanguageErrorText: 'Please select your language',
      registerCurrency: 'Preferred currency',
      registerCurrencyErrorText: 'Please selected your preferred currency',
      registerPasswordErrorText:
        'The password must be at least 5 characters long',
      repeatPass: 'Type the password again',
      recoverEmail: 'The e-mail address used for registration',
      recoverEmailErrorText: "The email address you've entered is not valid",
      resetBtn: 'Reset',
      passwordMismatch: 'The two passwords do not match',
      sendRecoverPasswordLink: 'Send reset link',
      resetEmailErrorText: 'You need to enter a valid e-mail address',
      resetPasswordErrorText: 'The password must be at least 5 characters long',
    },
    reset: {
      resetPassword: 'Reset password',
      confirmEmail: 'Confirm email address',
      newPassword: 'New password',
      confirmNewPassword: 'Confirm new password',
    },
    navigation: {
      dashboard: 'Dashboard',
      pendingOrders: 'Pending orders',
      clients: 'Clients',
      clientStatements: 'Client statements',
      invoicing: 'Invoicing',
      metrics: 'Work metrics',
      profile: 'Profile',
    },

    profile: {
      title: 'User profile',
      accountType: 'Account type',
      subscription: 'Subscription',
      timeZone: 'Time zone',
      update: 'Update',
      name: 'Company name',
      profileName: 'Profile name',
      currentPassword: 'Current password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm password',
      passwordRequirements: 'Password requirements',
      minChars: 'Minimum 6 characters long',
      currency: 'Currency',
      registeredOffice: 'Registered office',
      registrationNumber: 'Registration number',
      taxNumber: 'Tax number',
      bank: 'Bank',
      iban: 'IBAN',
      VATpayer: 'VAT-payer',
      VATrate: 'VAT rate',
      invoiceDefaultDue: 'Default invoice maturity (days)',
      invoiceStartNumber: 'Invoice start number',
      invoicePrefix: 'Invoice prefix',
      invoiceNotes: 'Notes to be included in invoices',
      bankAccounts: 'Bank accounts',
      receipts: 'Cash receipts',
      invoiceLogo: 'Logo to be included in invoice and messages',
      invoiceLogoNote: 'If no logo exists, the Zent logo will be used',
      emailReminders: 'E-mail reminders',
      sendEmail: 'Send e-mail',
      invoiceAlerts: 'Invoice reminders to clients',
      emailRemainderClientNote:
        'Only to clients having an e-mail address in the invoice. You will get a copy of the message.',
      beforeMaturity: 'One day before due date',
      twoDays: 'Two days late',
      fourDays: 'Four days late',
      sixDays: 'Six days late',
    },

    dashboard: {
      greeting: 'Welcome back',
      today: 'Today',
      youHave: 'you have',
      ordersToComplete: 'orders to complete',
      inTotal: 'In total',
      thereAre: 'there are',
      pendingOrders: 'pending orders',
      convertedValues:
        'Amounts in other currencies are converted to your preferred currency.',
      convertedUnits:
        'Units in characters or words are converted to one page equivalent',
      units: 'units',
      recentWork: 'Recent work',
      recentMoney: 'Gains',
      ordersToday: 'Orders to deliver today',
      invoicesThisWeek: 'Invoices due soon',
      invoicesOutstanding: 'Overdue',
      goToPendingOrders: 'go to pending orders',
      goToInvoices: 'go to invoices',
      outstanding: 'Outstanding',
      soonDue: 'Soon due',
      noOrders: 'No orders to deliver today',
      noInvoices: 'No invoices due this week',
      notes: 'Notes',
      activity: 'Activity',
      invoices: 'Invoices',
      noSoonDueInvoices: 'No invoices due this week',
      noOutstandingInvoices: 'No outstanding invoices',
      noOrdersToday: 'No orders for today',
      add: 'add notes...',
      pages: 'Pages',
      totalOrders: 'Total orders',
      clientStatistics: 'Client Statistics',
      chart: {
        yearChartTitle: 'Evolution of monthly earnings',
        earnings: 'earnings',
      },
    },
    pending: {
      title: 'Pending orders scheduler',
      addBtn: 'Add new order',
      noOrders: 'You have no pending orders',
    },
    clients: {
      header: 'Clients register',
      addBtn: 'Add new client',
      noClients: 'You have no clients added',
    },
    orders: {
      service: 'Job type',
      description: 'Description',
      client: 'Client',
      reference: 'Reference',
      receivedDate: 'Date received',
      deliveredDate: 'Date delivered',
      deadline: 'Deadline',
      changeDeadline: 'Change deadline',
      rate: 'Rate',
      editRate: 'Edit rate',
      mu: 'MU',
      count: 'Count',
      currency: 'Currency',
      status: 'Status',
      estimatedCount: 'Estimated count',
      estimatedTotal: 'Estimated total',
      editCount: 'Edit estimated count',
      finalCount: 'Final count',
      total: 'Total',
      notes: 'Notes',
      sorting: {
        all: 'all',
        today: 'for today',
        tomorrow: 'for tomorrow',
        thisWeek: 'for this week',
        pending: 'pending',
        completed: 'completed',
        invoiced: 'invoiced',
        notInvoiced: 'not invoiced',
      },
      statuses: {
        queue: 'Pending',
        completed: 'Completed',
        invoiced: 'Invoiced',
      },
    },
    items: {
      add: 'Add item',
      edit: 'Edit item',
    },
    client: {
      name: 'Name',
      phone: 'Phone',
      mobile: 'Mobile',
      email: 'Email',
      language: 'Language',
      rate: 'Rate',
      selectUnit: 'select unit...',
      selectCurrency: 'select currency...',
      taxNumber: 'Tax number',
      currency: 'Currency',
      mu: 'Measuring unit',
      registeredOffice: 'Registered office',
      mailingAddress: 'Mailing address',
      registrationNumber: 'Registration number',
      bank: 'Bank',
      iban: 'IBAN',
      representative: 'Representative',
      vatPayer: 'VAT-payer',
      invoiceDue: 'Invoice maturity (days)',
      invoiceNotes: 'Notes to be included in invoices',
      decimalPoints: 'Invoice decimal points',
      translationRate: 'Translation rate',
      proofreadingRate: 'Proofreading rate',
      posteditingRate: 'Post-editing rate',
      notes: 'Notes',
      alternateContacts: 'Alternate contacts',
      primaryContact: 'Primary contact',
      secondaryContact: 'Secondary contact',
      addedAt: 'Date added',
      totalInvoiced: 'Total invoiced',
      options: {
        changeAvatar: 'Change profile picture',
        changeLanguage: 'Change language',
        viewOrders: 'View order history',
        invoiceStatement: 'Invoice from work statement',
        invoiceSeparate: 'Separate invoice',
      },
    },
    statements: {
      title: 'Client statements',
      searchPlaceholder: 'search by name...',
      lastInvoicing: 'Last invoiced',
      noInvoice: 'no invoice issued',
      prevBalance: 'Previous balance',
      total: 'Total',
      totalBalance: 'Total balance',
      currentBalance: 'Current balance',
      filterAll: 'All clients',
      filterPositiveBalance: 'Positive balance',
      filterNegativeBalance: 'Negative balance',
      noResults: 'No results',
      uninvoicedAmount: 'Amount not invoiced',
      statement: {
        title: 'Work statement',
        receivedDelivered: 'Received/Delivered',
        invoicing: 'Invoicing',
      },
    },
    invoicing: {
      title: 'Invoices',
      invoiceData: 'Invoice data',
      addItems: 'Add items',
      addItem: 'Add item',
      addDiscount: 'Add discount',
      searchPlaceholder: 'search by client name...',
      listDetailedOrders: 'List detailed orders',
      invFilter: 'All',
      invFilterPos: 'Positive balance',
      invFilterNeg: 'Negative balance',
      period: 'Period',
      invoiceName: 'Invoice',
      statusIssued: 'issued',
      statusCashed: 'cashed',
      statusDelayed: 'delayed',
      statusReversed: 'reversed',
      options: {
        view: 'View',
        send: 'Send',
        cash: 'Cash',
        edit: 'Edit',
        delete: 'Delete',
        reverse: 'Reverse',
        cancel: 'Cancel',
        download: 'Download',
      },
      noPrefixSet: 'You have not set a prefix for invoices',
      noNameSet: 'You have not set your legal name',
      noTaxNumberSet: 'You have not set your tax number',
      goToProfile: 'Go to profile',
      invoiceInvalid:
        'There are several invalid settings, please review them in the pane.',
      selectClient: 'select a client...',
      events: {
        issued: 'Invoice issued',
        paid: 'Invoice paid',
      },
      invoice: {
        title: 'Invoice',
        prefix: 'prefix',
        number: 'no.',
        issuedDate: 'Date issued',
        maturity: 'Due by',
        status: 'Status',
        supplier: 'Supplier',
        client: 'Client',
        contact: 'Contact',
        items: 'Items',
        addDiscount: 'Add discount',
        addDiscountAmount: 'Amount',
        bank: 'Bank',
        addDiscountPercentage: 'Percent',
        discount: 'Discount',
        no: 'It.',
        typeRef: 'Job/Reference',
        qty: 'Quantity',
        description: 'Description',
        mu: 'MU',
        muLong: 'measurement unit',
        rate: 'Unit rate',
        vat: 'VAT',
        valueWithoutVAT: 'Value w/o VAT',
        valueWithVAT: 'Value + VAT',
        value: 'Value',
        toPay: 'Total to pay',
        subtotal: 'Subtotal',
        clientBalance: 'Client balance',
        remainder: 'Balance',
        itemsList: 'Items added',
        sendError: 'This client does not have an e-mail set',
        issue: 'Issue',
        reverse: 'Reverse',
        reverseHeading: 'reversing invoice',
        notes: 'Notes',
        deduction: 'Deducted',
        total: 'Total',
        paymentInfo: 'Payment methods',
        bankAccounts: 'Bank accounts',
        translationServices: 'Translation Services',
        dateCashed: 'Payment date',
        cashedAmount: 'Amount paid',
        receipt: 'Payment document',
        notPaid: 'Invoice is not paid',
      },
      view: {
        details: 'Details',
        payment: 'Payment',
      },
    },
    receipt: {
      title: 'Cash receipt',
      receiptPrefix: 'Cash receipt prefix',
      receiptStartNumber: 'Cash receipt start number',
      errorNoSet: 'No prefix and number set',
    },
    metrics: {
      title: 'Work metrics',
      today: 'Today',
      yesterday: 'Yesterday',
      currentWeek: 'Current week',
      lastWeek: 'Last week',
      currentMonth: 'Current month',
      lastMonth: 'Last month',
      last7Days: 'Income last 7 days',
      monthlyIncome: 'Monthly income',
      earnings: 'Earnings',
      dayBefore: 'related to the day before',
      weekBefore: 'related to the week before',
      monthBefore: 'related to the month before',
    },
    buttons: {
      openBtn: 'Open',
      backBtn: 'Back',
      addBtn: 'Add',
      editBtn: 'Edit',
      deleteBtn: 'Delete',
      saveBtn: 'Save',
      completeBtn: 'Complete',
      confirmBtn: 'Confirm',
      invoiceBtn: 'Invoice',
      exportBtn: 'Export PDF',
      sendBtn: 'Send',
      sendToClient: 'Send to client',
      issue: 'Issue',
      issueReverseBtn: 'Reverse',
      cashBtn: 'Cash',
      resetPasswordBtn: 'Reset password',
      optionsBtn: 'Options',
      cancelBtn: 'Cancel',
      detailsBtn: 'Details',
      resetBtn: 'Reset',
    },
    modals: {
      successHeader: 'Success',
      errorHeader: 'Error',
      user: {
        settings: {
          header: 'Application settings',
          language: 'Interface language',
          currency: 'Preferred currency',
          theme: 'Theme',
          accountType: 'User type: administrator',
        },
        personalData: {
          header: 'Update personal information',
          name: 'Name',
          nameErrorText: 'You need to provide a nick(name)',
          email: 'Email',
          contact: 'Contact phone',
        },
        professionalData: {
          header: 'Update personal information',
          name: 'Legal name',
          registeredOffice: 'Registered office',
          registrationNumber: 'Registration number',
          taxNumber: 'Tax number',
          VATpayer: 'VAT payer',
          VATrate: 'VAT rate',
        },
        invoicingData: {
          header: 'Update invoicing data',
          prefix: 'Invoice prefix',
          number: 'Invoice start number',
          defaultMaturity: 'Default maturity (days)',
          bank: 'Bank',
          iban: 'IBAN',
          invoiceMessage: 'Invoice sending message',
          statementMessage: 'Statement sending message',
          notes: 'Include the following notes in invoices',
        },
      },
      orders: {
        addOrder: {
          header: 'Add new order',
          selectClient: 'select a client...',
          serviceErrorText: 'You need to select a job type',
          clientErrorText: 'You need to select a client',
          receivedDateErrorText: 'You need to select a received date',
          deliveredDateErrorText: 'You need to select a delivered date',
          deadlineErrorText: 'You need to select a deadline',
          rateErrorText: 'You need to specify a rate',
          muErrorText: 'You need to select a measurement unit',
          estimatedCountErrorText: 'You need to specify an estimated count',
        },
        completeOrder: {
          header: 'Complete order',
          finalCountErrorText: 'You need to enter a final count',
        },
        editOrder: {
          header: 'Edit order',
          changeDeadline: 'Change deadline',
        },
        deleteOrder: {
          header: 'Delete item',
          confirmationMsg: 'Are you sure you want to delete this item?',
        },
        orderActions: {
          header: 'Order details',
        },
      },
      clients: {
        viewClient: {
          header: 'Client data',
          footer: 'Date added',
        },
        addClient: {
          onlyRoCompanies: 'Auto-fill only for Romanian companies',
          header: 'Add new client',
          taxNumberErrorText: 'You need to enter a tax number',
          nameErrorText: 'You need to enter a name',
          currencyErrorText: 'You need to select a currency',
          muErrorText: 'You need to select a measuring unit',
          languageErrorText: 'You need to select a language',
        },
        editClient: {
          header: 'Edit client',
        },
        deleteClient: {
          header: 'Delete client',
          prompt: 'Are you sure you want to delete this client?',
          subtitle:
            "All client's uninvoiced orders will be permanently deleted",
        },
      },
      statement: {
        send: {
          header: 'Send work statement',
          message: 'Message body',
          client: 'Client',
          emailErrorText: 'You need to enter an e-mail address',
        },
      },
      invoicing: {
        send: {
          header: 'Send invoice',
          message: 'Message body',
          client: 'Client',
          emailErrorText: 'You need to enter an e-mail address',
          includeStatement: 'Include detailed work statement',
        },
        cash: {
          header: 'Cash invoice',
          issuedTo: 'issued to',
          cashedVal: 'Amount cashed',
          docType: 'With document type/number',
          date: 'Date cashed',
          cashedValErrorText: 'You need to enter an amount',
          docTypeErrorText: 'You need to enter a document type/number',
          dateErrorText: 'You need to select a date',
        },
        edit: {
          header: 'Edit invoice',
          issuedTo: 'issued to',
          jobRef: 'Job/Ref.',
          options: 'Options',
          important: 'important',
          note1:
            'Changing individual items recalculates the total but it does not change the remainder (ADJUSTMENTS).',
          note2:
            'Changing the total disables the options on individual items and it does change the remainder (PARTIAL CASHING).',
        },
        cancel: {
          header: 'Cancel invoice',
          keepNote: 'Keep the items',
          note: 'The following items includes in this invoice can be either removed or kept for further invoicing',
        },
      },
    },
    charts: {
      pages: 'pages',
      perMonthLabel: 'Work chart by month',
      per7DaysLabel: 'Income in the past 7 days',
      last7Days: 'last 7 days',
      today: 'today',
      thisWeek: 'this week',
      thisMonth: 'this month',
      monthlyLabel: 'Monthly income',
      thisMonthLabel: 'Work completed this month',
      top5Clients: 'Top 5 clients by order values',
      gains: 'Gains',
      totalIssued: 'Total issued',
    },
    months: {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sep: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    },
    signature: 'Document generated with Zen-T Freelance',
  },
};
