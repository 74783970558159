import React from 'react';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerHeader = ({ open, handleDrawerClose }) => {
  return (
    <Header sx={{ opacity: open ? 1 : 0, mb: '3rem' }}>
      <img
        src="https://zent.s3.eu-west-3.amazonaws.com/zent-logo-blue.svg"
        style={{
          height: 40,
          width: 100,
          marginRight: 'auto',
        }}
      />

      <IconButton
        onClick={handleDrawerClose}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '5px',
          background: open ? '#eef8ff' : '',
          mt: '1rem',
        }}
      >
        {open ? <MenuOpenTwoToneIcon /> : <MenuTwoToneIcon />}
      </IconButton>
    </Header>
  );
};

export default DrawerHeader;
